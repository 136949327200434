<template>
    <div class="WayBill">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="130px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="项目" prop="saleBillId">
                        <el-select :ref="el=>refMap.set('saleBillId',el)" v-model="form.saleBillId" value-key="value" clearable placeholder="请选择项目" style="width: 100%" :disabled="disabled || disablSaleBillId" @change="(val)=>{selectOnChange(val,'saleBillId')}">
                            <el-option v-for="item in comboSelect({comboId:'saleBillId'})" :key="item.value" :label="item.label" :value="item" :disabled="item.disabled">
                                <div style="display: flex;justify-content: space-between;width: 500px">
                                    <el-col :span="8">{{ item.label.length>15?item.label.substr(0,15)+'...':item.label }}</el-col>
                                    <el-col :span="9" style="color: darkblue;font-size: 13px;">{{ item.item.customerName.length>15?item.item.customerName.substr(0,15)+'...':item.item.customerName  }}</el-col>
                                    <el-col :span="2" style="color: darkblue;font-size: 13px;">{{ item.item.saleType==0?'项目':'临时' }}</el-col>
                                    <el-col :span="5" style="color: darkblue;font-size: 13px;text-align: right;">{{ item.item.code }}</el-col>
                                </div>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="需求类型" prop="type">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="form.type" clearable placeholder="请选择需求类型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'type')}">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="销售类型" prop="saleType">
                        <el-select :ref="el=>refMap.set('saleType',el)" v-model="form.saleType" clearable placeholder="请选择销售类型" style="width: 100%" :disabled="true" @change="(val)=>{selectOnChange(val,'saleType')}">
                            <el-option v-for="item in comboSelect({comboId:'saleType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="客户" prop="customerId">
                        <el-select :ref="el=>refMap.set('customerId',el)" v-model="form.customerId" clearable placeholder="请选择客户" style="width: 100%" :disabled="true" @change="(val)=>{selectOnChange(val,'customerId')}">
                            <el-option v-for="item in comboSelect({comboId:'customerId'})" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="装货点" prop="customerAddressId">
                        <el-button type="info" :disabled="disabled" @click="chooseLoadPlace">选择装货点</el-button>
                        <el-tag type="success" style="margin-left: 20px;cursor: pointer" v-if="form.loadPlaceInfo" @click="loadPlaceInfoDetail">{{form.loadPlaceInfo}}</el-tag>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="要求装货时间" prop="loadTime">
                        <el-date-picker v-model="form.loadTime" placeholder="请选择要求装货时间" :disabled="disabled"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="要求送货时间" prop="deliveryTime">
                        <el-date-picker v-model="form.deliveryTime" placeholder="请选择要求送货时间" :disabled="disabled"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="代收货款" prop="collectionMoney">
                        <div style="display:flex;align-items: center;">
                            <div style="flex: 1"><el-input-number v-model="form.collectionMoney" :min="0" :max="99999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                            <div class="unit">元</div>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="委托单号" prop="name">
                        <el-input @input="e => form.authCode = validForbid(e)" v-model="form.authCode" placeholder="请输入委托单号" maxlength="50" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

        <el-tabs v-model="activeName" v-if="form.type">
            <el-tab-pane label="车型" v-if="form.type==1" name="wayBillAppointCx">
                <EditTable :ref="el=>refMap.set('wayBillAppointCx',el)" v-bind="cardDetailParam('wayBillAppointCx')">
                    <template v-slot:tbCols>
                        <el-table-column label="车型" prop="F_CX_ID"  >
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.CXNAME}}</span>
                                <el-select v-else :ref="el=>refMap.set('cxId',el)" v-model="scope.row.CXNAME" value-key="label" clearable placeholder="请选择车型"
                                           @change="(val)=>{selectOnChange(val,'cxId',scope.row,scope.$index)}" style="width: 100%;">
<!--                                    <el-option v-for="item in comboSelect({comboId:'cxId'})" :key="item.value" :label="item.label" :value="item.value"/>-->
                                    <el-option v-for="cx in comboSelect({comboId:'cxId'})" :key="cx.value" :label="cx.label" :value="cx"/>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column label="车辆载重" prop="F_CAPACITY"/>
                        <el-table-column label="车辆体积" prop="F_SIZE"/>
                        <el-table-column label="车长" prop="F_LENGTH"/>
                        <el-table-column label="车宽" prop="F_WIDTH"/>
                        <el-table-column label="车高" prop="F_HEIGHT"/>
                    </template>
                </EditTable>
            </el-tab-pane>

            <el-tab-pane label="货物明细" v-if="form.type==2" name="wayBillAppointGoodsDetail">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="货物件数">
                            <el-input-number v-model="form.num" :min="0" placeholder="请输入货物件数" :max="9999999" precision=0 :disabled="disabled" style="width: 100%" clearable/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="预估重量">
                            <el-input-number v-model="form.weight" :min="0" placeholder="请输入预估重量" :max="9999999" precision=0 :disabled="disabled" style="width: 100%" clearable/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="预估体积">
                            <el-input-number v-model="form.size" :min="0" placeholder="请输入预估体积" :max="9999999" precision=0 :disabled="disabled" style="width: 100%" clearable/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <EditTable :ref="el=>refMap.set('wayBillAppointGoodsDetail',el)" v-bind="cardDetailParam('wayBillAppointGoodsDetail')">
                    <template v-slot:tbCols>
                        <el-table-column label="货物名称" prop="F_NAME"  >
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_NAME}}</span>
                                <el-input v-else @input="e => scope.row.F_NAME = validForbid(e)" placeholder="请输入货物名称" v-model="scope.row.F_NAME" clearable/>
                            </template>
                        </el-table-column>
                    </template>
                </EditTable>
            </el-tab-pane>
        </el-tabs>
        </el-form>
    </div>
</template>


<script>
    import WayBillCardHelper from "./WayBillCardHelper.js";
    export default WayBillCardHelper;
</script>

<style scoped>
    .WayBill{
        width: 100%;
    }
    .unit{
        width: 20px;font-size: 13px;padding-left: 5px;
    }
</style>
