import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const WayBillCardHelper = defineComponent({
    name:'WayBillCard',
    title:'运单管理',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            disablSaleBillId:false,
            refMap:new Map(),
            activeName:'',
            customerData:[],
            cxData:[],
            saleBillData:[],
            saleBillTempValue:'',//保存失败的时候用到
            loadPlaceInfo:'',//装货地址信息
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                // details:['wayBillAppointCx','wayBillAppointGoodsDetail'],
                details:[],
                modelPath: "/wayBill"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                saleBillId: [
                    {required: true, message: "请选择项目", trigger: "blur" }
                ],
                // saleType: [
                //     {required: true, message: "请选择销售类型", trigger: "blur" }
                // ],
                // customerId: [
                //     {required: true, message: "请选择客户", trigger: "blur" }
                // ],
                loadTime: [
                    {required: true, message: "请选择要求装货时间", trigger: "blur" }
                ],
                deliveryTime: [
                    {required: true, message: "请选择要求送货时间", trigger: "blur" }
                ]
            }
        })
        onMounted(()=>{})
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'saleType'==params.comboId){
                    return [{value:0,label:'项目'},{value:1,label:'临时'}]
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.customerData;
                }
                if(params && 'saleBillId'==params.comboId){
                     return dataObj.saleBillData;
                }
                if(params && 'type'==params.comboId){
                     return [{value:1,label:'车'},{value:2,label:'货'}]
                }
                if(params && 'cxId'==params.comboId){
                     return dataObj.cxData
                }
            }
        })
        const buildTitle=(detailType)=>{
            if('wayBillAppointCx'==detailType){
                return '车型';
            }
            if('wayBillAppointGoodsDetail'==detailType){
                return '货物';
            }
        }
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType) => {
                return {
                    detailParam: {
                        canAdd:true,
                        // title:WayBillCardHelper.title,
                        title:buildTitle(detailType),
                        readOnly:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: "/wayBill/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue,selectId,row,rowIndex)=>{//row、rowIndex是针对明细表格车型的选择而言
            if('saleType'==selectId){}
            if('customerId'==selectId){

            }
            if('saleBillId'==selectId){//清空得时候，newValue为空
                if(newValue){
                    dataObj.form.saleType=newValue.item.saleType;
                    dataObj.form.customerId=newValue.item.customerId;
                }else{
                    dataObj.form.saleType='';
                    dataObj.form.customerId='';
                }
                //如果需求类型为车，那么需要把明细清除，因为明细数据需要根据销售单来过滤
                if(dataObj.form.type==1){
                    dataObj.refMap.get('wayBillAppointCx').clearDetailData();
                }
                //如果不是清空，那么就再次构建车型
                if(newValue.value)await buildCxData({saleBillId:newValue.value});
                dataObj.form.loadPlaceInfo='';
                dataObj.form.customerAddressId='';
            }
            if('type'==selectId){
                if(newValue){
                    if(newValue==1){
                        dataObj.activeName='wayBillAppointCx';//不能去掉哦
                        dataObj.compParams.details=['wayBillAppointCx'];
                        if(dataObj.form.saleBillId)await buildCxData({saleBillId:dataObj.form.saleBillId.value});
                    }else if(newValue==2){
                        dataObj.activeName='wayBillAppointGoodsDetail';//不能去掉哦
                        dataObj.compParams.details=['wayBillAppointGoodsDetail'];
                    }
                }else{
                    dataObj.activeName='';//不能去掉哦
                    dataObj.compParams.details=[];
                }
            }
            if('cxId'==selectId){
                row['CXNAME']=newValue.item.name;
                row['F_CX_ID']=newValue.item.id;
                row['F_CAPACITY']=newValue.item.capacity;
                row['F_SIZE']=newValue.item.size;
                row['F_LENGTH']=newValue.item.length;
                row['F_WIDTH']=newValue.item.width;
                row['F_HEIGHT']=newValue.item.height;
            }
        }
        const buildCustomerData=async (params)=>{
            dataObj.customerData= await utils.$$api.getCustomers(params);
        }
        const buildCxData=async (params)=>{
            dataObj.cxData= await utils.$$api.getCx(params);
        }
        const buildSaleBillData=async (customerId)=>{
            dataObj.saleBillData= await utils.$$api.getProjectSaleBills({});
        }
        const setParam=(cardEngineInst)=>{
            if(cardEngineInst.engineParams.from){
                return {from:cardEngineInst.engineParams.from}
            }
            return {}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if('/load'==addOrLoad)dataObj.disablSaleBillId=true;
            if(data.data.type==0)dataObj.form.type='';
            //从运输列表点击运单号打开运单，from==WayBillCarList
            if(cardEngine.engineParams.from || data.data.overPc==1)dataObj.disabled=true;
            await buildCustomerData();
            if(dataObj.form.type==1) await buildCxData({saleBillId:data.data.saleBillId});//一定要在下方之前，否则就把data.data.saleBillId改变了
            await buildSaleBillData(data.data.customerId);
            if(data.data.saleBillId){
                dataObj.saleBillData.forEach((item)=>{
                    if(item.value==data.data.saleBillId)dataObj.form.saleBillId=item;
                })
            }
            if(dataObj.form.type==1){
                dataObj.activeName='wayBillAppointCx';//不能去掉哦
                dataObj.compParams.details=['wayBillAppointCx'];
                await dataObj.refMap.get('wayBillAppointCx').reloadGrid({mainId:dataObj.form.id});
            }else if(dataObj.form.type==2){
                dataObj.activeName='wayBillAppointGoodsDetail';//不能去掉哦
                dataObj.compParams.details=['wayBillAppointGoodsDetail'];
                await dataObj.refMap.get('wayBillAppointGoodsDetail').reloadGrid({mainId:dataObj.form.id});
            }else{
                dataObj.activeName='';//不能去掉哦
                dataObj.compParams.details=[];
            }
        }
        const beforeAddHandler=(detailType, row)=>{
            if('wayBillAppointCx'==detailType && !dataObj.form.saleBillId){
                proxy.$message('请先选择项目');
                return false;
            }
            return true;
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType,options)=>{
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(detailType, row, index)=>{
            if("wayBillAppointCx"==detailType){
                // row.CXNAME = dataObj.refMap.get('cxId').selected.currentLabel;//设置下拉select选取的文本值到行中
                // dataObj.refMap.get('wayBillAppointCx').setRowData(row, index);
            }
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            if(dataObj.form.saleType==0 && !dataObj.form.saleBillId){
                proxy.$message({showClose: true, message: '请先选择项目', type: 'info'});
                return false;
            }
            if(!dataObj.form.customerAddressId){
                proxy.$message({showClose: true, message: '请先选择装货点', type: 'info'});
                return false;
            }
            //验证明细表格数据是否合法
            if(dataObj.form.type==1){
                let wayBillAppointCxData=dataObj.refMap.get('wayBillAppointCx').getDetailData();
                if(wayBillAppointCxData.length==0){
                    utils.$$tools.warning({message:"车型明细表格中没有任何数据"});
                    return false;
                }
                let set=new Set();
                for(let i=0;i<wayBillAppointCxData.length;i++){
                    if(!wayBillAppointCxData[i]['F_CX_ID']){
                        utils.$$tools.warning({message:'车型第'+(i+1)+"行不能为空"});
                        return false;
                    }
                    set.add(wayBillAppointCxData[i]['F_CX_ID']);
                }
                if(set.size!=wayBillAppointCxData.length){
                    utils.$$tools.warning({message:"车型明细表格中存在重复的车型"});
                    return false;
                }
            }

            if(dataObj.form.type==2){
                let wayBillAppointGoodsDetailData=dataObj.refMap.get('wayBillAppointGoodsDetail').getDetailData();
                if(wayBillAppointGoodsDetailData.length==0){
                    utils.$$tools.warning({message:"货物明细表格中没有任何数据"});
                    return false;
                }
                let set=new Set();
                for(let i=0;i<wayBillAppointGoodsDetailData.length;i++){
                    if(!wayBillAppointGoodsDetailData[i]['F_NAME']){
                        utils.$$tools.warning({message:'货物明细第'+(i+1)+"行不能为空"});
                        return false;
                    }
                    set.add(wayBillAppointGoodsDetailData[i]['F_NAME']);
                }
                if(set.size!=wayBillAppointGoodsDetailData.length){
                    utils.$$tools.warning({message:"货物明细表格中存在重复的货物"});
                    return false;
                }
            }
            dataObj.saleBillTempValue=dataObj.form.saleBillId;//存起来，失败的时候还原
            //由于项目下拉select绑定的是一个对象，所有这里需要把这个对象变为一个值(变为一个一个值的方法就是去这个对象中的一个属性)
            dataObj.form.saleBillId=dataObj.form.saleBillId.value;

            return true;
        }
        //保存失败，重新初始化下拉项目控件，因为保存之前已经改变了他
        const saveFail=async ()=>{
            dataObj.form.saleBillId=dataObj.saleBillTempValue;
        }
        //选择装货地址
        const chooseLoadPlace=async ()=>{
            if(!dataObj.form.customerId){
                proxy.$message({showClose: true, message: '请先选择项目', type: 'info'});
            }else{
                await utils.$$lghdUtils.buildChooseAddressDialog({from:'chooseLoadPlace',proxy:proxy,customerId:dataObj.form.customerId});
            }
        }
        //选择卸货地址
        const chooseUnloadAddress=async (param)=>{
            if(!dataObj.form.customerId){
                proxy.$message({showClose: true, message: '请先选择项目', type: 'info'});
            }else{
                await utils.$$lghdUtils.buildChooseAddressDialog({from:'chooseUnloadAddress',proxy:proxy,customerId:dataObj.form.customerId});
            }
        }

        //点击装货地址详情(算了，不提供了)
        const loadPlaceInfoDetail=()=>{

        }
        //地图弹出框回调事件，在这可以拿到弹出框的实例，里面有选择的地址坐标等信息
        const sureHandler=async (modelInst)=>{
            let selectRows = modelInst.pageListRef.getTbInst().getSelection();//F_COORDINATE坐标
            if(selectRows.length>0){
                if('chooseLoadPlace'==modelInst.engineParams.from){
                    dataObj.form.loadPlaceInfo=selectRows[0]['F_ADDRESS'];
                    dataObj.form.customerAddressId=selectRows[0]['F_ID'];
                }
            }
            return true;
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,cardDetailParam,beganEdit,beforeEndEdit,setParam,beforeAddHandler,beforeOpen,beforeSaveHandler,
            chooseLoadPlace,chooseUnloadAddress,loadPlaceInfoDetail,sureHandler,saveFail
         //,gridLoaded
        }
    }
});
export default WayBillCardHelper;